/********************** Load more cards *******************************/
function loadMoreListingCards() {
  const loadMoreButton = document.getElementById("load-more-button");
  let initialNumberOfCards = 3;
  const loadMoreCards = [...document.querySelectorAll(".load-more-cards")];
  if (loadMoreButton) {
    if (loadMoreCards.length <= 2) {
      loadMoreButton.style.display = "none";
    }
    loadMoreButton.addEventListener("click", function () {
      for (let i = initialNumberOfCards; i < initialNumberOfCards + 3; i++) {
        if (loadMoreCards[i]) {
          loadMoreCards[i].style.display = "block";
        }
      }
      initialNumberOfCards += 3;
      if (initialNumberOfCards >= loadMoreCards.length) {
        loadMoreButton.style.display = "none";
      }
    });
  }
}

/********************** Listing filters *******************************/

function setURLSearchParam({ key, value }) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.replaceState({ path: url.href }, "", url.href);
}

function deleteURLSearchParam({ key }) {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);
  window.history.replaceState({ path: url.href }, "", url.href);
}

function handleFilterButtonOnClick({
  button,
  buttons,
  dropdownToggle,
  noOfFilters,
}) {
  button.addEventListener("click", function () {
    for (const btn of buttons) {
      btn.classList.remove("active");
    }

    let filterTypeKey = dropdownToggle.getAttribute("data-type");
    const filterValue = this.innerHTML;
    filterTypeKey = filterTypeKey.replace("Select", "").trim();

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get(filterTypeKey) === filterValue) {
      deleteURLSearchParam({ key: filterTypeKey });

      if (filterTypeKey.includes("Latest First")) {
        dropdownToggle.innerHTML = filterTypeKey;
      } else {
        dropdownToggle.innerHTML = `Select ${filterTypeKey}`;
      }

      button.classList.remove("active");

      dropdownToggle.classList.remove("btn-primary");
      dropdownToggle.classList.add("btn-secondary");
    } else {
      dropdownToggle.innerHTML = filterValue;
      if (
        filterValue.includes("Select") ||
        filterValue.includes("Latest First")
      ) {
        deleteURLSearchParam({ key: filterTypeKey });
        dropdownToggle.classList.add("btn-secondary");
        dropdownToggle.classList.remove("btn-primary");
      } else {
        setURLSearchParam({ key: filterTypeKey, value: filterValue });
        button.classList.add("active");

        dropdownToggle.classList.remove("btn-secondary");
        dropdownToggle.classList.add("btn-primary");
      }
    }

    const listingCards =
      document.getElementsByClassName("listing-cards")[0]?.parentElement;
    if (listingCards) {
      if (!window.location.search && noOfFilters > 1) {
        listingCards.style.display = "none";
      } else {
        listingCards.style.display = "block";
      }
    }
  });
}

function enableListingFilters() {
  const url = new URL(`${window.location.origin}${window.location.pathname}`);
  window.history.replaceState({ path: url.href }, "", url.href);

  const listingFilters = document.getElementsByClassName("listing-filters");

  for (let filter of listingFilters) {
    const dropdowns = filter.childNodes;
    for (const [index, dropdown] of dropdowns?.entries()) {
      const buttons = dropdown?.getElementsByClassName("dropdown-item");
      for (const button of buttons) {
        handleFilterButtonOnClick({
          button,
          buttons,
          dropdownToggle: filter.childNodes[index].firstChild,
          noOfFilters: dropdowns?.length,
        });
      }
    }
  }
}

/********************** Accordion Scroll To Top *******************************/
function accordionScrollToTop() {
  const collapsePanelButtons = document.querySelectorAll(".accordion-button");
  for (const button of collapsePanelButtons) {
    button.addEventListener("click", function () {
      const btnParent = button.closest(".cloudsmart-world-accordion");
      window.scrollTo({
        behavior: "smooth",
        top: btnParent.offsetTop + 170,
      });
    });
  }
}

/********************** Active tab pane ***********************/

function activeTabPane() {
  const collapsTab = document.querySelector(".applications-assessment-tab");
  if (collapsTab) {
    const solutiontab = document.querySelector(
      ".applications-assessment-tab-title"
    );
    const solutiondetails = document.getElementById("solutionDetalis");
    solutiontab.className += " active";
    solutiondetails.className += " active";
  }
}

/*** ******************* Region Filter ***********************/

function regionFilter() {
  let selectedFilters = [];
  let filterLists = document.querySelectorAll(
    ".partner-logo-grid__filter-list"
  );
  let filterLogo = document.querySelector(".partner-logo-grid__wrapper");
  const filters = [...document.querySelectorAll(".filter select")];

  if (filters) {
    for (const filter of filters) {
      filter.addEventListener("change", function (event) {
        let selectChild = filterLogo.querySelectorAll(
          ".partner-logo-grid__filter-list"
        )[0];
        selectChild.style.display = "none";

        selectedFilters = filters.map((filter) => filter.value).filter(Boolean);
        for (const filterList of filterLists) {
          filterList.classList[
            selectedFilters.some((filter) =>
              filterList.classList.contains(filter)
            )
              ? "add"
              : "remove"
          ]("visible");
        }
      });
    }
  }
}

/********************** Sales Circle ***********************/
function showFeaturedCards() {
  const [salesCircelV2] = document.getElementsByClassName(
    "sales-circle-v2-page"
  );
  if (salesCircelV2) {
    const tabPanes = salesCircelV2.querySelectorAll(".tab-pane");
    for (let tabPane of tabPanes) {
      if (tabPane) {
        const loadMoreCards = [...tabPane.querySelectorAll(".load-more-cards")];

        const loadMoreButton = tabPane.querySelector(".listing-cards-btn");
        if (loadMoreButton) loadMoreButton.style.display = "none";

        for (let card of loadMoreCards) {
          const isFeatured = card.getAttribute("data-is-featured");
          if (isFeatured === "false") {
            card.style.display = "none";
          }
        }

        const checkboxs = salesCircelV2.querySelectorAll(
          "input[type=checkbox]"
        );

        for (let checkbox of checkboxs) {
          checkbox.addEventListener("change", function () {
            const totalNoCheckedCheckboxes = salesCircelV2.querySelectorAll(
              'input[type="checkbox"]:checked'
            )?.length;
            if (totalNoCheckedCheckboxes > 0) {
              for (let card of loadMoreCards) {
                card.style.display = "block";
              }
              if (loadMoreButton) loadMoreButton.style.display = "block";
            } else {
              for (let card of loadMoreCards) {
                const isFeatured = card.getAttribute("data-is-featured");
                if (isFeatured === "false") {
                  card.style.display = "none";
                }
                if (loadMoreButton) loadMoreButton.style.display = "none";
              }
            }
          });
        }

        const clearAllFiltersButton =
          salesCircelV2.querySelector(".clear-all-filters");

        clearAllFiltersButton?.addEventListener("click", function () {
          for (let checkbox of checkboxs) {
            checkbox.checked = false;
          }
          for (let card of loadMoreCards) {
            const isFeatured = card.getAttribute("data-is-featured");
            if (isFeatured === "false") {
              card.style.display = "none";
            }
          }
          if (loadMoreButton) loadMoreButton.style.display = "none";
        });

        function handleEachClearFilter(id) {
          const clearFilterBtn = document.getElementById(
            `${id}-clear-filter-btn`
          );
          clearFilterBtn?.addEventListener("click", function () {
            const filter = document.getElementById(`${id} Id`);
            if (filter) {
              const checkboxes = filter.querySelectorAll(
                "input[type=checkbox]"
              );
              for (let checkbox of checkboxes) {
                checkbox.checked = false;
              }

              const totalNoCheckedCheckboxes = salesCircelV2.querySelectorAll(
                'input[type="checkbox"]:checked'
              )?.length;

              if (totalNoCheckedCheckboxes === 0) {
                for (let card of loadMoreCards) {
                  const isFeatured = card.getAttribute("data-is-featured");
                  if (isFeatured === "false") {
                    card.style.display = "none";
                  }
                }
                if (loadMoreButton) loadMoreButton.style.display = "none";
              }
            }
          });
        }

        [
          "Content Types",
          "Offerings",
          "Services",
          "assets",
          "Industries",
          "Partners",
          "Region",
        ].forEach((id) => handleEachClearFilter(id));
      }
    }
  }
}

function handleQueryParameters() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params["Type"] || params["type"]) {
    let type = params["Type"] || params["type"];
    const internalOrExternalBtn = document.querySelector(
      `button[aria-controls="${type}"]`
    );

    if (internalOrExternalBtn) internalOrExternalBtn.click();
  }

  const [salesCircelV2] = document.getElementsByClassName(
    "sales-circle-v2-page"
  );

  Object.values(params).forEach((value) => {
    let checkbox = salesCircelV2.querySelector(
      `input[type=checkbox][id="${value}"]`
    );
    if (checkbox) checkbox.click();
  });
}

/********************** Global map ***********************/
function globalMap() {
  const popup = document.getElementById("popup");
  const mapHover = document.getElementById("map");
  const content = document.getElementById("popup-content");
  const closer = document.getElementById("popup-closer");
  const accWrapper = document.querySelector(".accordion");
  const modalBox = document.querySelector(".modalBox");
  const labName = document.querySelector(".lab-name");
  const establishedIn = document.querySelector(".established-in");
  const locationAddress = document.querySelector(".location-address");
  const Technologies = document.querySelector(".technologies");
  const modalDescription = document.querySelector(".description");

  function dropdownFilter() {
    const labService = document.querySelector(".lab-service");

    if (labService) {
      labService.addEventListener("change", function (event) {
        let labValue = labService.value;
        document
          .querySelectorAll(".cloudsmart-world-accordion__wrapper")
          .forEach((n) => n.classList.remove("visible"));
        document
          .querySelectorAll(".cloudsmart-world-accordion__wrapper")
          .forEach((n) => n.classList.add("d-none"));
        document
          .querySelector(".cloudsmart-world-accordion__wrapper." + labValue)
          .classList.add("visible");
        document
          .querySelector(".cloudsmart-world-accordion__wrapper." + labValue)
          .classList.remove("d-none");
      });
    }
  }
  dropdownFilter();

  if (popup) {
    popup.addEventListener("mouseover", function () {
      event.stopPropagation();
    });
    popup.onclick = function () {
      popup.style.display = "none";
    };
  }

  if (accWrapper) {
    accWrapper.onclick = function () {
      setTimeout(function () {
        map.updateSize();
      }, 100);
    };
  }

  if (closer) {
    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
  }

  let extent = [0, 0, 1114, 524];
  let projection = new ol.proj.Projection({
    code: "static-image",
    units: "pixels",
    extent: extent,
  });
  const overlay = new ol.Overlay({
    element: popup,
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
  });

  function getZoomLeval() {
    const width = screen.availWidth + 300;
    return Math.ceil(Math.LOG2E * Math.log(width / 600));
  }
  const getZoom = getZoomLeval();

  let initialZoom;
  if (screen.width < 767) {
    initialZoom = getZoom - 0.4;
  } else if (screen.width < 991) {
    initialZoom = getZoom + 0.3;
  } else {
    initialZoom = getZoom;
  }

  let dragging;
  if (screen.width < 767) {
    dragging = true;
  } else {
    dragging = false;
  }

  const map = new ol.Map({
    layers: [
      new ol.layer.Image({
        source: new ol.source.ImageStatic({
          url: "/_global/_assets/images/map.png",
          projection: projection,
          imageExtent: extent,
        }),
      }),
    ],
    target: "map",
    view: new ol.View({
      extent: [-180, -40, 1310, 780],
      projection: projection,
      center: ol.extent.getCenter(extent),
      zoom: initialZoom,
      bgcolor: "0x80BDE3",
      minZoom: initialZoom,
    }),
    interactions: ol.interaction.defaults({
      doubleClickZoom: false,
      dragAndDrop: false,
      dragPan: dragging,
      keyboardPan: false,
      keyboardZoom: false,
      mouseWheelZoom: false,
    }),
    overlays: [overlay],
    controls: ol.control.defaults({
      attribution: false,
      zoom: false,
    }),
  });

  if (screen.width > 1025) {
    if (popup) {
      mapHover.addEventListener("mouseover", function () {
        popup.style.display = "none";
      });
      mapHover.onclick = function () {
        popup.style.display = "none";
      };
    }
  }

  async function mapData() {
    const labdata = document.getElementById("labdata");
    if (labdata) {
      const labdatajson = labdata.value;
      const response = await fetch(labdatajson);
      const data = await response.json();
      const getattr = popup.getAttribute("data-bs-target", "id");

      let modalId = document.querySelector(".modalBox").id;
      modalBox.dataset.id = getattr;

      if (getattr) {
        modalId = getattr.replace("#", "");
      }

      document.querySelector(".modalBox").id = modalId;
      let mapModalData = data.find((Location, i) => Location.id === modalId);
      let final = [];
      let dropArray = [];

      mapModalData.services.forEach((element, s) => {
        let serviceLabel = element.service;
        let serviceId = serviceLabel.replace(/\W/g, "-").toLowerCase();

        dropArray.push(`<option value="${serviceId}">${serviceLabel}</option>`);

        if (s > 0) {
          final.push(
            `<div class="row cloudsmart-world-accordion__wrapper d-none ${serviceId}">`
          );
        } else {
          final.push(
            `<div class="row cloudsmart-world-accordion__wrapper ${serviceId}">`
          );
        }

        element.downloads.forEach((downloads, i) => {
          final.push(`<div class="col-lg-3 d-flex mb-4">
              <div
                class="cloudsmart-world-accordion__block bg-white text-center"
              >`);
          if (downloads.fileUrl.indexOf(".pdf") > 0) {
            final.push(
              `<img src="/_global/_assets/images/icons/pdf.png" alt="${downloads.name}" />`
            );
          } else if (downloads.fileUrl.indexOf(".ppt") > 0) {
            final.push(
              `<img src="/_global/_assets/images/icons/ppt.png" alt="${downloads.name}" />`
            );
          } else {
            final.push(
              `<img src="/_global/_assets/images/icons/mic.png" alt="${downloads.name}" />`
            );
          }
          final.push(`<h5 class="pt-3 pb-6 m-auto cloudsmart-world-accordion__title">${downloads.name}</h5>
                <a href="${downloads.fileUrl}" class="text-uppercase pt-3"
                  >Download</a>
              </div>
              </div>`);
        });
        final.push(element.serviceContent);
        final.push(`</div>`);
      }),
        (document.querySelector(
          ".cloudsmart-world-accordion__labs-filter select"
        ).innerHTML = dropArray.join(""));
      document.querySelector(
        ".cloudsmart-world-accordion__labs-service"
      ).innerHTML = final.join("");

      labName.childNodes[1].innerHTML = `${mapModalData.labName}`;
      establishedIn.childNodes[1].innerHTML = `${mapModalData.establishedIn}`;
      locationAddress.childNodes[1].innerHTML = `${mapModalData.location.address}`;
      Technologies.childNodes[1].innerHTML = `${mapModalData.technologiesAvaliable}`;
      modalDescription.childNodes[1].innerHTML = `${mapModalData.description}`;
    }
  }

  if (screen.width < 1025) {
    map.on("singleclick", function (evt) {
      mapData();
      let description = map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature.get("description");
        }
      );
      let id = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature.get("id");
      });
      let name = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature.get("name");
      });
      let focusAreas = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature.get("focusAreas");
      });
      if (id) {
        popup.setAttribute("data-bs-target", `#${id}`);
      }
      if (description) {
        popup.style.display = "block";
        let coordinate = evt.coordinate;
        document.querySelector(".popup-labname").innerText = name;
        document.querySelector(".focus-areas").innerHTML = focusAreas;
        overlay.setPosition(coordinate);
      }
    });
  }

  map.on("pointermove", function (evt) {
    mapData();
    let description = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature.get("description");
    });
    let id = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature.get("id");
    });
    let name = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature.get("name");
    });
    let focusAreas = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature.get("focusAreas");
    });

    if (id) {
      popup.setAttribute("data-bs-target", `#${id}`);
    }
    if (description) {
      popup.style.display = "block";
      let coordinate = evt.coordinate;
      document.querySelector(".popup-labname").innerText = name;
      document.querySelector(".focus-areas").innerHTML = focusAreas;
      overlay.setPosition(coordinate);
    }
  });

  map.on("pointermove", function (evt) {
    map.getTargetElement().style.cursor = map.hasFeatureAtPixel(evt.pixel)
      ? "pointer"
      : "";
  });
  const pStyle = new ol.style.Style({
    image: new ol.style.Circle({
      radius: 7,
      fill: new ol.style.Fill({
        color: "#0066b3",
      }),
      stroke: new ol.style.Stroke({
        color: "white",
        width: 3,
      }),
    }),
  });

  async function getMapData() {
    const labdata = document.getElementById("labdata");

    if (labdata) {
      const labdatajson = labdata.value;
      const response = await fetch(labdatajson);
      const data = await response.json();

      for (let mapInfo of data) {
        const layer = new ol.layer.Vector({
          source: new ol.source.Vector({
            features: [
              new ol.Feature({
                geometry: new ol.geom.Point([
                  `${mapInfo.location.xCoordinate}`,
                  `${mapInfo.location.yCoordinate}`,
                ]),
                description: `<h3 class="text-primary pb-5">${mapInfo.labName}</h3><h4 class="text-primary pb-2">Focus Areas:</h4><ul><li>${mapInfo.focusAreas}</li></ul>`,
                id: `${mapInfo.id}`,
                name: `${mapInfo.labName}`,
                focusAreas: `${mapInfo.focusAreas}`,
              }),
            ],
          }),
          style: pStyle,
        });
        map.addLayer(layer);
      }
    }
  }
  getMapData();
}

/********************** Active Links ***********************/
function activeLinks() {
  let navLinks = document.querySelectorAll(".nav-link-wrapper .nav-link");
  let path = window.location.pathname;
  let element = document.getElementById("curPage");
  let currentPage = element ? element.value?.replaceAll("/", "") : "";

  if (navLinks) {
    for (let navLink of navLinks) {
      const navItem = navLink.closest(".nav-item");
      if (navItem) {
        let href = navLink?.getAttribute("href");
        if (currentPage && href?.includes(currentPage)) {
          navItem.classList.add("active");
        } else if (href === path) {
          navItem.classList.add("active");
        } else {
          navItem.classList.remove("active");
        }
      }
    }
  }
}

/********************** Initiate function calls ***********************/
window.addEventListener("load", () => {
  // loadMoreListingCards();
  // enableListingFilters();
  accordionScrollToTop();
  activeTabPane();
  regionFilter();
  // showFeaturedCards();
  // handleQueryParameters();
  activeLinks();
});

document.addEventListener("DOMContentLoaded", function () {
  globalMap();
});

/*****************************************************************************************
 * Search G2 Constants
 ****************************************************************************************/

const isAbsolutePath =
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("netlify");

// const serverUrl = "https://dev.hclcloudsmart.webscale.support";
const serverUrl = "https://sit.hclcloudsmart.com/";
const baseUrl = isAbsolutePath ? `${serverUrl}/api/search?` : "/api/search?";

/********************** Sales Circle Search G2 ***********************/

const salesCircleQuery = `${baseUrl}q=*&wt=json&start=0&rows=1000&fl=id&fl=custom_dt_startdate&fl=custom_dt_enddate&fl=custom_s_external_internal&fl=custom_s_content_type&fl=custom_s_local_url&fl=custom_s_type&fl=custom_b_featured&fl=custom_s_title&fl=custom_s_summary&fl=custom_ss_industry_groups&fl=custom_ss_authors&fl=custom_ss_partners&fl=custom_ss_region&fl=custom_ss_offerings&fl=custom_ss_industries&fl=custom_ss_industry_types&fl=custom_ss_industry_groups&fl=custom_ss_services&fl=custom_ss_assets&facet=true&facet.field=custom_s_content_type&facet.field=custom_ss_offerings&facet.field=custom_ss_partners&facet.field=custom_ss_industries&facet.field=custom_ss_region&facet.field=custom_ss_assets&facet.field=custom_ss_services&facet.field=custom_ss_industry_groups&facet.mincount=1&&sort=field(custom_dt_startdate) desc&fq=custom_s_type:"ContentType"%20OR%20custom_s_type:"Partner"`;

// const salesCircleQuery = "./content/sales.json";

const localStorageKeys = {
  "hcl-cloudsmart__sales-circle__content-types":
    "hcl-cloudsmart__sales-circle__content-types",
  "hcl-cloudsmart__sales-circle__filtered-content-types":
    "hcl-cloudsmart__sales-circle__filtered-content-types",
  "hcl-cloudsmart__sales-circle__facet-fields":
    "hcl-cloudsmart__sales-circle__facet-fields",
  "hcl-cloudsmart__sales-circle__current-filters":
    "hcl-cloudsmart__sales-circle__current-filters",
};

const listContainer = {
  internal: "internal",
  external: "external",
};

const totalContentTypesCount = 9;

/*****************************************************************************************
 * Utilities
 ****************************************************************************************/
function setLocalStorageItem(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function getLocalStorageItem(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function fetchContentTypes() {
  return new Promise(function (resolve, reject) {
    const httpRequest = new window.XMLHttpRequest();

    httpRequest.open("GET", salesCircleQuery);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.send();

    httpRequest.onload = function () {
      if (httpRequest.status !== 200) {
        updateSpinnerUiState();
        showEmptyContentMessage({
          text: "Something went wrong, try again!",
          container: listContainer["external"],
        });
        showEmptyContentMessage({
          text: "Something went wrong, try again!",
          container: listContainer["internal"],
        });
        reject(null);
      } else {
        const contentTypes = JSON.parse(httpRequest.response);
        setLocalStorageItem(
          localStorageKeys["hcl-cloudsmart__sales-circle__facet-fields"],
          contentTypes?.facet_counts.facet_fields
        );
        setLocalStorageItem(
          localStorageKeys["hcl-cloudsmart__sales-circle__content-types"],
          contentTypes?.response?.docs
        );
        setLocalStorageItem(
          localStorageKeys[
            "hcl-cloudsmart__sales-circle__filtered-content-types"
          ],
          contentTypes?.response?.docs
        );
        resolve(contentTypes);
      }
    };
    httpRequest.onerror = function () {
      updateSpinnerUiState();
      showEmptyContentMessage({
        text: "Something went wrong, try again!",
        container: listContainer["external"],
      });
      showEmptyContentMessage({
        text: "Something went wrong, try again!",
        container: listContainer["internal"],
      });
    };
  });
}

function isEmpty(obj) {
  if (obj === null || obj === undefined) return true;
  return Object.keys(obj).length === 0;
}

function formartDate(date) {
  if (date === "" || date === null || date === undefined) return "";

  let newDate = new Date(date);
  let day = ("0" + newDate.getDate()).slice(-2);
  let month = newDate.toLocaleString("default", { month: "short" });
  let year = newDate.getFullYear();
  return `${month} ${day}, ${year}`;
}

function titleCase(phrase = "", splitter = "-") {
  return phrase
    .toLowerCase()
    .split(splitter)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function slugCase(phrase = "") {
  return phrase
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

function lowerCase(phrase = "") {
  return phrase?.toLowerCase();
}

function isString(phrase = "") {
  return typeof phrase === "string";
}

function isArray(items = []) {
  return Array.isArray(items);
}

function groupBy(items, key) {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
}

function has(cachedFilters, key) {
  return (
    Object.prototype.hasOwnProperty.call(cachedFilters, key) &&
    cachedFilters[key].length > 0
  );
}

function contains({ filterFrom = [], filterValue }) {
  if (isString(filterValue)) {
    return filterFrom.includes(filterValue);
  } else if (isArray(filterValue)) {
    return filterFrom.filter((type) => filterValue.includes(type)).length > 0;
  } else {
  }
}

function areCachedFiltersEmpty(cachedFilters) {
  return Object.values(cachedFilters).every((list) => list.length === 0);
}

function groupParamsByKey(params) {
  return [...params.entries()].reduce((acc, tuple) => {
    const [key, val] = tuple;
    if (acc.hasOwnProperty(key)) {
      acc[key] = [acc[key], val].join(",");
    } else {
      acc[key] = val;
    }
    return acc;
  }, {});
}

function paramsObjectToString(params) {
  let queryString = "";
  Object.entries(params).forEach(([key, value], index) => {
    let string = `${index != 0 ? "&" : ""}${key}=${value}`;
    queryString += string;
  });
  return queryString;
}

/*****************************************************************************************
 * Start
 ****************************************************************************************/
(async function () {
  setLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__content-types"],
    []
  );
  setLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__filtered-content-types"],
    []
  );
  setLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__content-types"],
    {}
  );
  setLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__current-filters"],
    {}
  );

  const contentTypes = await fetchContentTypes();

  updateSpinnerUiState();

  if (!isEmpty(contentTypes)) {
    showContentTypesList();
    showFilters();
    handleFilters();
    handleEachClearFilter();
    handleClearAllFilters();
  } else {
    showEmptyContentMessage({
      text: "No results found, try again!",
      container: listContainer["external"],
    });
    showEmptyContentMessage({
      text: "No results found, try again!",
      container: listContainer["internal"],
    });
  }
})();

function handleClearAllFilters() {
  const clearAllFiltersButton = document.querySelector(".clear-all-filters");
  clearAllFiltersButton?.addEventListener("click", function () {
    filters.forEach((filter) => {
      let id = slugCase(filter.heading);
      const clearFilterBtn = document.getElementById(`${id}-clear-filter-btn`);
      clearFilterBtn.click();
    });
  });
}

function handleEachClearFilter() {
  filters.forEach((filter) => {
    let id = slugCase(filter.heading);
    const clearFilterBtn = document.getElementById(`${id}-clear-filter-btn`);
    clearFilterBtn?.addEventListener("click", function () {
      const filter = document.getElementById(`${id}-filter-id`);
      if (filter) {
        const checkboxes = filter.querySelectorAll("input[type=checkbox]");
        for (let checkbox of checkboxes) {
          if (checkbox.checked) {
            checkbox.checked = false;
            updateCachedFilters({ element: checkbox, action: "un-checked" });
          }
        }
        showContentTypesList();
      }
    });
  });
}

/*****************************************************************************************
 * Render cards
 ****************************************************************************************/

function filterInternalExternalContentTypes(contentTypes) {
  return {
    internal: contentTypes.filter(
      (contentType) =>
        contentType["custom_s_external_internal"]?.toLowerCase() === "internal"
    ),
    external: contentTypes.filter(
      (contentType) =>
        contentType["custom_s_external_internal"]?.toLowerCase() === "external"
    ),
  };
}

function showContentTypesList() {
  const contentTypes = getLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__filtered-content-types"]
  );
  const { internal, external } =
    filterInternalExternalContentTypes(contentTypes);

  if (!isEmpty(internal)) {
    createCards({
      contentTypes: internal,
      container: listContainer["internal"],
    });
  } else {
    showEmptyContentMessage({ container: listContainer["internal"] });
  }

  if (!isEmpty(external)) {
    createCards({
      contentTypes: external,
      container: listContainer["external"],
    });
  } else {
    showEmptyContentMessage({ container: listContainer["external"] });
  }
}

function createCards({ contentTypes, container }) {
  const contentTypesContainer = document.getElementById(container);
  if (contentTypesContainer) {
    contentTypesContainer.innerHTML = "";

    let cachedFilters = getLocalStorageItem(
      localStorageKeys["hcl-cloudsmart__sales-circle__current-filters"]
    );

    if (areCachedFiltersEmpty(cachedFilters)) {
      contentTypes = contentTypes.filter((type) => type.custom_b_featured);
    }

    contentTypes.forEach((contentType) => {
      let id = contentType?.id;
      let isFeatured = contentType?.custom_b_featured;
      let title = contentType?.custom_s_title || "";
      let startDate = contentType?.custom_dt_startdate || "";
      let endDate = contentType?.custom_dt_enddate || "";
      let body = contentType?.custom_s_summary || "";
      let url = contentType?.custom_s_local_url;

      const card = `${
        (title || body || startDate || endDate) &&
        `<a
        id="${id}"
        href="${url}"
        class="listing-cards-card load-more-cards bg-secondary mb-5"
        data-is-featured="${isFeatured}"
      >
        ${
          isFeatured
            ? `<div class="mb-1">
            <span class="badge bg-primary">Featured</span>
          </div>`
            : ""
        }
        <div class="listing-cards__subheader">
          ${
            title &&
            `<div class="listing-cards__subheader-title text-primary">
            ${title}
          </div>`
          }
          ${
            (startDate || endDate) &&
            `
            <div class="listing-cards__subheader-date mb-4">
               ${startDate && formartDate(startDate)}
              ${endDate ? `to ${formartDate(endDate)}` : ""}
            </div>
            `
          }
        </div>
        ${body && `<p>${body}</p>`}
      </a>`
      }
    `;

      contentTypesContainer.insertAdjacentHTML("beforeend", card);
    });
  }
}

/*****************************************************************************************
 * Render filters
 ****************************************************************************************/

const filters = [
  {
    heading: "Content Types",
    facetField: "custom_s_content_type",
  },
  {
    heading: "Offerings",
    facetField: "custom_ss_offerings",
  },
  {
    heading: "Services",
    facetField: "custom_ss_services",
  },
  {
    heading: "Assets",
    facetField: "custom_ss_assets",
  },
  {
    heading: "Partners",
    facetField: "custom_ss_partners",
  },
  {
    heading: "Industries",
    facetField: "custom_ss_industries",
  },
  {
    heading: "Region",
    facetField: "custom_ss_region",
  },
];

function showFilters() {
  const facetFields = getLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__facet-fields"]
  );

  for (const facetField in facetFields) {
    let contentTypesFilter = filters.find(
      (filter) => filter.facetField === facetField
    );

    if (
      contentTypesFilter !== null &&
      contentTypesFilter !== undefined &&
      !isEmpty(contentTypesFilter)
    ) {
      contentTypesFilter["sections"] = [];

      if (contentTypesFilter.facetField === "custom_ss_industries") {
        // Prepartion for industry types and groups separation
        let formattedIndustryTypes = facetFields["custom_ss_industry_groups"]
          .filter((field) => !Number(field) && field !== 0)
          // .map((type) => type)
          .sort((a, b) => (a > b ? 1 : -1));

        const valueHeadingGroup = [];
        formattedIndustryTypes.forEach((industryType) => {
          let [label, heading] = industryType.split("|");
          if (heading) {
            valueHeadingGroup.push({
              label,
              heading,
            });
          }
        });

        const groupedValueHeadingTypes = groupBy(valueHeadingGroup, "heading");

        for (const [heading, list] of Object.entries(
          groupedValueHeadingTypes
        )) {
          contentTypesFilter["sections"].push({
            subHeading: heading,
            list: list,
          });
        }
      } else {
        contentTypesFilter["sections"].push({
          subHeading: "",
          list: facetFields[facetField]
            .filter((field) => !Number(field) && field !== 0)
            .sort((a, b) => (a > b ? 1 : -1))
            .map((label) => ({
              label,
            })),
        });
      }
    }
  }

  createFilter();
}

function createFilter() {
  const filtersContainer = document.getElementById("sales-circle-filters");
  if (filtersContainer) {
    filtersContainer.innerHTML = "";

    function createList(section, filter) {
      let items = "";
      for (let item of section.list) {
        items += `
      <div class="form-check mb-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="${slugCase(item.label)}"
          data-facet-field=${filter.facetField}
          data-label="${item.label}"
          data-slug="${slugCase(item.label)}"
        />
        <label class="form-check-label" for="${slugCase(item.label)}">
          <span>${item.label}</span>
        </label>
      </div>`;
      }
      return items;
    }

    function createSections(filter) {
      let sections = "";
      for (let section of filter.sections) {
        sections += `
        <div>
          ${
            section.subHeading &&
            `<p class="fw-bold text-black-50 mb-2">${section.subHeading}</p>`
          }
          <div class="${section.subHeading ? "ps-3" : "ps-1"}">
            ${createList(section, filter)}
          </div>
        </div>
      `;
      }
      return sections;
    }

    filters.forEach((filter) => {
      const filterCard = `
      <div class="filter-with-checkboxes border p-4">
        <div id="${slugCase(filter.heading)}-filter-id">
          <p class="mb-3 text-primary fw-bold">${filter.heading}</p>
          <div class="filter-with-checkboxes__sections">
            <div class="form-check-list d-grid gap-3">
            ${createSections(filter)}
            </div>
          </div>
          <div class="pt-3">
            <button
              id="${slugCase(filter.heading)}-clear-filter-btn"
              class="btn btn-link btn-sm p-0"
            >
              Clear filter
            </button>
          </div>
        </div>
      </div>
    `;

      filtersContainer.insertAdjacentHTML("beforeend", filterCard);
    });
  }
}

/*****************************************************************************************
 * Filter actions
 ****************************************************************************************/
function handleFilters() {
  let salesCirclePage = document.querySelector(".sales-circle-v2-page");
  if (salesCirclePage) {
    const checkboxs = salesCirclePage.querySelectorAll("input[type=checkbox]");

    for (let checkbox of checkboxs) {
      checkbox.addEventListener("change", function (e) {
        if (this.checked) {
          updateCachedFilters({ element: this, action: "checked" });
        } else {
          updateCachedFilters({ element: this, action: "un-checked" });
        }
      });
    }

    // Read query params and update checkbox state
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.forEach((value, key) => {
      if (value) {
        value.split(",").forEach((paramValue) => {
          let checkbox = salesCirclePage.querySelector(
            `input[type=checkbox][id="${paramValue}"]`
          );
          if (checkbox) {
            checkbox.click();
          }
        });
      }
    });
  }
}

function updateCachedFilters({ element, action }) {
  const cachedFilters = getLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__current-filters"]
  );

  const key = element.getAttribute("data-facet-field");
  const label = element.getAttribute("data-label");
  const slug = element.getAttribute("data-slug");

  let selectedFilter = filters.find((filter) => filter.facetField === key);

  let queryKey = slugCase(selectedFilter.heading);

  if (action === "checked") {
    setUrlSearchParam({ key: queryKey, slug });

    if (cachedFilters?.[key]) cachedFilters[key].push(label);
    else cachedFilters[key] = [label];
  } else if (action === "un-checked") {
    if (cachedFilters?.[key]) {
      removeUrlSearchParam({ key: queryKey, slug });
      const index = cachedFilters[key].indexOf(label);
      if (index > -1) cachedFilters[key].splice(index, 1);
    }
  }
  setLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__current-filters"],
    cachedFilters
  );
  generateContentTypesFilteredList();
}

function generateContentTypesFilteredList() {
  const allContentTypes = getLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__content-types"]
  );
  const cachedFilters = getLocalStorageItem(
    localStorageKeys["hcl-cloudsmart__sales-circle__current-filters"]
  );

  const filteredContentTypes = allContentTypes.filter(function (contentType) {
    // Start of filter 7
    if (
      /***********************************************
       * Filter for 7 combination
       ***********************************************/
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }
    // Start of filter 6
    else if (
      /***********************************************
       * Filter for 6 combination
       ***********************************************/
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }
    // Start of filter 5
    if (
      /***********************************************
       * Filter for 5 combination
       ***********************************************/
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }

    /////////////

    // Start of filter 4
    else if (
      /***********************************************
       * Filter for 4 combination
       ***********************************************/
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }
    // Start of filter 3
    else if (
      /***********************************************
       * Filter for 3 combination
       ***********************************************/
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }
    // Start of filter 2
    else if (
      /***********************************************
       * Filter for 2 combination
       ***********************************************/
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_offerings")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_services")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_s_content_type") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_services")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_offerings") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_assets")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_services") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_partners")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_assets") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_industries")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_partners") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    } else if (
      has(cachedFilters, "custom_ss_industries") &&
      has(cachedFilters, "custom_ss_region")
    ) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        }) &&
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }
    // Start of filter 1
    else if (has(cachedFilters, "custom_s_content_type")) {
      /***********************************************
       * Filter for 1 combination
       ***********************************************/
      if (
        contains({
          filterFrom: cachedFilters.custom_s_content_type,
          filterValue: contentType?.custom_s_content_type,
        })
      ) {
        return true;
      }
    } else if (has(cachedFilters, "custom_ss_offerings")) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_offerings,
          filterValue: contentType?.custom_ss_offerings,
        })
      ) {
        return true;
      }
    } else if (has(cachedFilters, "custom_ss_services")) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_services,
          filterValue: contentType?.custom_ss_services,
        })
      ) {
        return true;
      }
    } else if (has(cachedFilters, "custom_ss_assets")) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_assets,
          filterValue: contentType?.custom_ss_assets,
        })
      ) {
        return true;
      }
    } else if (has(cachedFilters, "custom_ss_partners")) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_partners,
          filterValue: contentType?.custom_ss_partners,
        })
      ) {
        return true;
      }
    } else if (has(cachedFilters, "custom_ss_industries")) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_industries,
          filterValue: contentType?.custom_ss_industries,
        })
      ) {
        return true;
      }
    } else if (has(cachedFilters, "custom_ss_region")) {
      if (
        contains({
          filterFrom: cachedFilters.custom_ss_region,
          filterValue: contentType?.custom_ss_region,
        })
      ) {
        return true;
      }
    }
  });

  if (filteredContentTypes.length > 0) {
    setLocalStorageItem(
      localStorageKeys["hcl-cloudsmart__sales-circle__filtered-content-types"],
      filteredContentTypes
    );
    showContentTypesList();
  } else if (
    !areCachedFiltersEmpty(cachedFilters) &&
    filteredContentTypes.length === 0
  ) {
    setLocalStorageItem(
      localStorageKeys["hcl-cloudsmart__sales-circle__filtered-content-types"],
      filteredContentTypes
    );
    showEmptyContentMessage({ container: listContainer["internal"] });
    showEmptyContentMessage({ container: listContainer["external"] });
  } else {
    setLocalStorageItem(
      localStorageKeys["hcl-cloudsmart__sales-circle__filtered-content-types"],
      allContentTypes
    );
    showContentTypesList();
  }
}

/*****************************************************************************************
 * Empty states, null content & query parameters
 ****************************************************************************************/

function showEmptyContentMessage({ text = "No matches found", container }) {
  const tabContainer = document.getElementById(container);

  if (tabContainer) {
    tabContainer.innerHTML = "";

    const message = `
    <div class="p-8 text-center">
    <b>${text}</b>
    </div>
    `;

    tabContainer.insertAdjacentHTML("beforeend", message);
  }
}

function updateSpinnerUiState() {
  const salesCircleSpinner = document.getElementById("sales-circle-spinner");
  if (salesCircleSpinner) {
    salesCircleSpinner.style.display = "none";
  }
}

function setUrlSearchParam({ key, slug }) {
  const url = new URL(window.location.href);
  let isValueExist = url.searchParams.getAll(key)[0]?.toString().includes(slug);

  if (!isValueExist) {
    url.searchParams.append(key, slug);
    const paramsList = groupParamsByKey(new URLSearchParams(url.search));
    url.search = paramsObjectToString(paramsList);
    window.history.replaceState({ path: url.href }, "", url.href);
  }
}

function removeUrlSearchParam({ key, slug }) {
  let url = new URL(window.location.href);
  let qParam = url.searchParams
    .getAll(key)[0]
    .replaceAll(slug, "")
    .split(",")
    .filter((v) => v)
    .join(",");

  const paramsList = groupParamsByKey(new URLSearchParams(url.search));
  if (qParam) {
    paramsList[key] = qParam;
    url.search = paramsObjectToString(paramsList);
    window.history.replaceState({ path: url.href }, "", url.href);
  } else {
    url.searchParams.delete(key);
    window.history.replaceState(
      { path: decodeURIComponent(url.href) },
      "",
      decodeURIComponent(url.href)
    );
  }
}

/********************** Accordion Search G2 ***********************/

function fetchCSWaysContentTypes({ dType, dValue }) {
  let fqValue;
  dValue = decodeURIComponent(dValue);
  if (dValue.includes(",")) {
    fqValue = `${dType}:"${dValue.split(",").join(`" OR ${dType}:"`)}"`;
  } else {
    fqValue = `${dType}:"${dValue}"`;
  }

  let cloudSmartWaysQuery = `${baseUrl}q=*&wt=json&start=0&rows=4&sort=field(custom_dt_startdate) desc&fq=custom_s_type:ContentType&fq=${encodeURIComponent(
    fqValue
  )}`;

  return new Promise(function (resolve, reject) {
    const httpRequest = new window.XMLHttpRequest();

    httpRequest.open("GET", cloudSmartWaysQuery);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.send();

    httpRequest.onload = function () {
      if (httpRequest.status !== 200) {
        updateCloudSmartSpinnerUiState({ id: "cloudsmart-spinner" });
        reject(null);
      } else {
        const contentTypes = JSON.parse(httpRequest.response);
        resolve(contentTypes);
      }
    };
    httpRequest.onerror = function () {
      updateCloudSmartSpinnerUiState({ id: "cloudsmart-spinner" });
      reject(null);
    };
  });
}

(async function () {
  let accordionList = document.querySelectorAll(
    ".accordion-item[data-type][data-value]"
  );
  if (accordionList.length) {
    let accordionItemPromises = [];
    for (let item of accordionList) {
      let dType = item.getAttribute("data-type");
      let dValue = item.getAttribute("data-value");
      if (dType && dValue) {
        accordionItemPromises.push(fetchCSWaysContentTypes({ dType, dValue }));
      }
    }

    let acordionItemResponse = await Promise.all(accordionItemPromises);
    if (acordionItemResponse.length) {
      updateCloudSmartSpinnerUiState({ id: "cloudsmart-spinner" });

      let dTypeValues = [];
      accordionList.forEach((item) => {
        dTypeValues.push(item?.getAttribute("data-type"));
      });

      for (let [index, item] of acordionItemResponse.entries()) {
        let fqValue = item?.responseHeader?.params.fq[1]?.replaceAll(
          `${dTypeValues[index]}:`,
          ""
        );

        let list = item?.response?.docs;
        if (fqValue.includes("OR")) {
          fqValue = fqValue
            .replaceAll(" OR ", ",")
            .replaceAll('"', "")
            .split(",")
            .map((s) => encodeURIComponent(s))
            .join(",");
        } else {
          fqValue = encodeURIComponent(fqValue.replaceAll('"', ""));
        }

        let elements = document.querySelectorAll(`[data-value="${fqValue}"]`);

        if (elements.length) {
          for (const element of elements) {
            if (element) {
              let cardList = element.querySelector(
                ".cloudsmart-world-accordion__cardlist"
              );

              if (cardList.childElementCount === 1) {
                createAccordionCards({ list, container: cardList });
              }
            }
          }
        }
      }
    }
  }
})();

function updateCloudSmartSpinnerUiState({ id }) {
  const spinners = document.querySelectorAll(`.${id}`);
  if (spinners.length) {
    for (const spinner of spinners) {
      spinner.style.display = "none";
    }
  }
}

function createAccordionCards({ list, container }) {
  if (container) {
    list?.forEach((item) => {
      let id = item?.id || "";
      let title = item?.custom_s_title || "";
      let summary = item?.custom_s_summary || "";
      let startDate = item?.custom_dt_startdate || "";
      let endDate = item?.custom_dt_enddate || undefined;
      let authors = item?.custom_ss_authors || [];
      let url = item?.custom_s_local_url || "";

      let card = `<div class="cloudsmart-world-accordion__cardlist-card" id=${id}>
            <div class="cloudsmart-world-accordion__subheader">
              ${
                title
                  ? `<div class="cloudsmart-world-accordion__subheader-title">
                  <a href="${url}" class="link-primary">
                    ${title}
                  </a>
                </div>
              `
                  : ""
              }
              <div class="cloudsmart-world-accordion__subheader-date">
                ${startDate ? `<span>${formartDate(startDate)}</span>` : ""}
                ${endDate ? `<span>to&nbsp;${formartDate(endDate)}</span>` : ""}
                ${
                  authors.length > 0
                    ? `
                  <span class="cloudsmart-world-accordion__subheader-bytext">By</span>
                  <span>${authors?.toString()?.split(",")?.join(", ")}</span>
                `
                    : ""
                }
              </div>
            </div>
            ${summary && `<p>${summary}</p>`}
          </div>`;

      container.insertAdjacentHTML("beforeend", card);
    });
  }
}
